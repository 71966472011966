<template>
  <div>
    <div
      class=" font-weight-light"
      :class="{'pa-5 headline': $vuetify.breakpoint.lgAndUp,'pa-2 subtitle-1': $vuetify.breakpoint.mdOnly, 'pa-1 body-2': $vuetify.breakpoint.smAndDown}"
    >
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Potential Energy </span>
      </div>
      <div ref="ia" style="margin-top: 20px" />
      Potential Energy of a body that is under the influence of a force is always stated with respect
      to some reference point. At the reference point, the potential energy is considered to be zero.
      The potential energy change on moving from \(x=x_A\) to \(x=x_B\) under the influence of a force \(F\)
      is given by:
      $$U_B-U_A=-\int_{x_A}^{x_B} \textbf{F}\cdot d\textbf{x}$$
      If we take \(x=x_A\) as the reference point, and measure the value of potential energy <i>with respect to</i> A,
      the potential energy at A can be taken as 0.
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Elastic Energy stored in a spring </span>
      </div>
      <div ref="ib" style="margin-top: 20px" />
      Potential energy may be present in many forms. In case of a spring, when we stretch it, the spring has some
      "potential" to move/do work. Therefore, it possesses some potential energy. If we choose the natural length(zero extension/compression)
      of the spring as the reference point and define our origin there, we can calculate the potential energy due a displacement \(\textbf{x}\)
      as:
      $$U=-\int_{0}^x (-k)\textbf{x}\cdot d\textbf{x}=\frac{1}{2}kx^2$$
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Learn Interactively | Force and Energy in a Spring </span>
      </div>
      <div ref="ic" style="margin-top: 20px" />
      Shown in the MagicGraph is a spring that is connected to a block on one end and tied to a rigid wall
      on the another end. The block can be dragged on the floor without any friction.
      <div ref="ic" style="margin-top: 20px" />
      <h5>Session Objectives </h5>
      <div ref="id" style="margin-top: 10px" />
      In this visually interactive module, you will learn about the restoring force and elastic energy stored in a spring.
      <div ref="ic" style="margin-top: 20px" />
      <h5>Getting Started </h5>
      <div ref="ie" style="margin-top: 10px" />
      Start by pulling or pushing the block on frictionless floor. As you pull or push the block, the spring stretches or compresses. Observe how the restoring force and stored energy change with the amount of extenstion or compression.
    </div>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5 class="edliy-text-about">
          Quick Tip: Drag the block to stretch or compress the spring. Use <i class="shuffler ma-1" /> button to switch between force and energy. Use <i class="undoer ma-1" /> button to reset sprint to its natural length.
        </h5>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Resistors',
  created: function () {
    // Store mutations
    let title = 'Restoring Force and Stored Energy';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Hooke\'s Law', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Implications of Hooke\'s law', img:'/assets/number-2.svg', action: () => this.goto('ca')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Springs',
          titleTemplate: '%s | Lesson 2',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Balancing a chemical equation.'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
