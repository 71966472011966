import {
  makeResponsive,
  //makeResponsive(brd1);
  placeTitle,
  //placeTitle(brd1,'title', 'subtitle');
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  //placeArrow(board, pt1, pt2, width, cols)
  placeGravity,
  placeText,
  //placeText(board, positionX, positionY, message)
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeSliderSwitch,
  placeBoldText,
  //placeBoldText(brd6, -9, 1, 'Liquid');
  placeDash,
  // placeDash(board, [x1, y1], [x2, y2], width, cols)
  writeRightText,
  writeLeftText,
  placeLabel,
  placeLogo,
  placeShuffle,
  toggle,
  writeHTMLText,
  alert,
  writeMiddleText,
  placeStartOver
} from '../Utils';
const Boxes = {
    box1: function () {
      var board1 = JXG.JSXGraph.initBoard('jxgbox1', {boundingbox:[-4,10,12,-6], axis:false, grid:true,
        keepaspectratio:true, showNavigation:false, showCopyright:false, zoom:{enabled:false}, pan:{enabled:false}});
      makeResponsive(board1);
      board1.options.layer['axis'] =2;
      board1.options.layer['polygon'] =5;
      board1.options.layer['glider'] =11;
      board1.options.layer['point'] =11;
      board1.options.layer['segment'] =4;
      board1.options.layer['image'] =17;
      board1.options.layer['text'] =12;
      board1.options.image.highlight=false;
      board1.options.point.highlight=false;
      board1.options.line.highlight=false;
      board1.options.point.showInfoBox=false;
      var i=0;
      placeLogo(board1);
      placeTitle(board1, 'Force & Energy of a Spring', '');
      let xAxis =board1.create('axis', [[0, 0],[5, 0]], { strokeColor:'grey',
        highlight:false, strokeWidth: ()=>Math.round(2*board1.canvasWidth/800.),
        withLabel:true, name: '', straightFirst:false, straightLast:true, lastArrow:false,
          label: {
            display:'internal',
            fontSize:function(){return 24*board1.canvasHeight/800},
            CssStyle: 'fontFamily:Oswald;fontWeight:bold',
            position: 'rt',
            offset: [-20, 20]}});
//
      let yAxis =board1.create('axis', [[5, 0],[5, +1]], { strokeColor:'grey',
              highlight:false, strokeWidth: ()=>Math.round(2*board1.canvasWidth/800.),
              withLabel:true, name: '', straightFirst:false, straightLast:true, lastArrow:false,
                label: {
                  display:'internal',
                  fontSize:function(){return 24*board1.canvasHeight/800},
                  CssStyle: 'fontFamily:Oswald;fontWeight:bold',
                  position: 'rt',
                  offset: [-20, 20]}});

//
		var k1 = board1.create('slider',[[-2.,0],[-2.,4],[1, 2.5, 5]],{baseline:{strokeWidth:7, strokeColor:'grey',lineCap:'round'},highline:{strokeWidth:7, strokeColor:'black',lineCap:'round'}, name:'&nbsp; &nbsp;k', unitLabel:' N/m', size:8, face:'circle', fillColor:'grey',strokeColor:'black', withTicks:false, label:{offset:[0,25], fontSize:function(){return 18*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
//
	  var line1 = board1.create('line', [[1,-2], [100, -2]], {visible: false, straightFirst: false, straightLast: false});

    var msg = writeMiddleText(board1, 4, 8.5, function(){if(i==0){return '(Plot of Energy vs. Deflection)'}else{return ' (Plot of of Force vs. Deflection)'}});
    msg.setAttribute({visible:true, color:'red'});
//
    var shuffle =placeShuffle(board1,'left');
    shuffle.on('down', function(){i=toggle(i);});

    var sover = placeStartOver(board1, 'right');
//
	  var hand = board1.create('image', ['/assets/right.svg', [6.7, -2.1],[2,2]],{rotate:90});
//
	  board1.on('move', function(){hand.moveTo([hand.X(), -2.1])});
//
	  var point1 = board1.create('glider', [function(){return hand.X()-1.7}, -2, line1], {name: '', color:'blue', size:function(){return 36*board1.canvasHeight/800}, face:'square'});
//
	  var force1 = board1.create('arrow', [[function(){return point1.X();}, -2], [function(){return point1.X()-Math.sign(point1.X()-5)*2;}, -2] ], {visible:()=>i==1, strokeWidth:4, strokeColor:'black', point2:{size:1, face:'square', name:'F'}});
//
	  board1.create('text', [function(){return point1.X()-2.25*Math.sign(point1.X()-5);}, -2, 'F'], {display:'internal', color:'blue', visible:()=>point1.X()!=5&&i==1, fontSize:function(){return 18*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
	  var massvalue1 = board1.create('text', [function(){return point1.X();}, -2.,'M'], {fontSize:function(){return 18*board1.canvasHeight/800}, display:'internal', anchorX:'middle', cssStyle:'fontFamily:Oswald;'});
//
	  var kvalue1 = board1.create('text', [function(){return point1.X()*0.5;}, 0.5-2,'k'], {display:'internal', fontSize:function(){return 18*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
	  board1.create('segment', [[5, -0.75-2], [5, -2-1.5] ], {strokeWidth:1, strokeColor:'black',dash:1});
//
    board1.create('segment', [[function(){return point1.X();}, -0.75-2], [function(){return point1.X()}, 2-2] ], {dash:1, strokeWidth:1, strokeColor:'black'});
//
	  board1.create('segment', [[function(){return point1.X()}, 1.5-2],[0, 1.5-2] ], {fixed:true, strokeColor:'black', strokeWidth:1});
//
	   board1.create('point', [function(){return point1.X()-0.1}, 1.5-2], {name: '', color:'black', size:function(){return 4*board1.canvasHeight/800}, face:'triangleright'});
//
	   board1.create('point', [0.1, 1.5-2], {name: '', color:'black', size:function(){return 4*board1.canvasHeight/800}, face:'triangleleft', fixed:true});
//
	   board1.create('point', [4.9, -1.-2], {name: '', face:'triangleright', color:'black', size:function(){return 4*board1.canvasHeight/800}, fixed:true});
//
	   board1.create('point', [0.1, -1.-2], {name: '', face:'triangleleft', color:'black', size:function(){return 4*board1.canvasHeight/800}, fixed:true});
//
	   board1.create('segment', [[5, -1.-2],[0, -1.-2] ], {fixed:true, strokeColor:'black', strokeWidth:1});
//
	   board1.create('text', [2.25, -3.-0.25, 'L<sub>o</sub> = 5.00 m'], {fixed:true,
    fontSize:function(){return 18*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
	  board1.create('text', [()=>point1.X()/2, 1.75-2.5, function(){return 'L =' + (point1.X()).toFixed(2) + ' m'}],
    {fixed:true, anchorX:'middle',
    fontSize:function(){return 18*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    board1.create('functiongraph', [function(x){return 0.5*k1.Value()*(x-5)*(x-5)}, 5, function(){return point1.X()}],{strokeColor:'red', strokeWidth:4, highlight:false, visible:()=>i==0});

    board1.create('functiongraph', [function(x){return k1.Value()*Math.abs(x-5)}, 5, function(){return point1.X()}],{strokeColor:'blue', strokeWidth:4, highlight:false, visible:()=>i==1});
//
    var energy =board1.create('point', [function(){return point1.X();}, function(x){return 0.5*k1.Value()*(point1.X()-5.0)*(point1.X()-5.0)}],{name:'', size:()=>5*board1.canvasWidth/800, face:'+', strokeColor:'black', fillColor:'black', visible:()=>i==0});
//
    var force =board1.create('point', [function(){return point1.X();}, function(x){return k1.Value()*Math.abs(point1.X()-5.0)}],{name:'', size:()=>5*board1.canvasWidth/800, face:'+', strokeColor:'black', fillColor:'black', visible:()=>i==1});
//
    placeDash(board1, [()=>energy.X(), ()=>energy.Y()], [()=>energy.X(), 0], 1, 'black').setAttribute({visible:()=>i==0});
//
    placeDash(board1, [()=>energy.X(), ()=>energy.Y()], [5, ()=>energy.Y()], 1, 'black').setAttribute({visible:()=>i==0});
//
    placeDash(board1, [()=>force.X(), ()=>force.Y()], [()=>force.X(), 0], 1, 'black').setAttribute({visible:()=>i==1});;
//
    placeDash(board1, [()=>force.X(), ()=>force.Y()], [5, ()=>force.Y()], 1, 'black').setAttribute({visible:()=>i==1});
//
//    writeRightText(board1, 4.75, ()=>energy.Y(), ()=>(energy.Y()).toFixed(2)+' J').setAttribute({visible:()=>i==0});
    writeRightText(board1, 4.75, 7.5, 'U(J)').setAttribute({visible:()=>i==0});

    writeHTMLText(board1, 10.5, 0.5, 'L-L_0(m)');
//
    writeRightText(board1, 4.75, 7.5, 'F(N)').setAttribute({visible:()=>i==1});
//
//    writeRightText(board1, 4.75, ()=>force.Y(), ()=>(force.Y()).toFixed(2)+' N').setAttribute({visible:()=>i==1})
//
//    board1.create('text', [3, -4.5, function(){if(point1.X()>5){return 'Spring is Elongated (L > L<sub>0</sub>)'} else if(point1.X()<5){return 'Spring is Compressed (L < L<sub>0</sub>)'} else{return 'Spring is at Equilibrium Length (L = L<sub>0</sub>)'}}],{fixed:true, fontSize:function(){return 20*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    var isInDragMode = false;
//
	  var springHangup1 = board1.create('point', [0, -2], {color: 'black', name: '', fixed: true, visible:false});
//
	  var i1=0;
	  var numberOfSpringRings1 = 50;
	  var springRings1 = [];
//
	for(i1=0; i1<numberOfSpringRings1; i1++) {
    springRings1[i1] = board1.create('point', [function(i1){return function(){return (point1.X()/numberOfSpringRings1)*i1};}(i1), 0.5*(i1%2-0.5)-2], {withLabel: false, visible:false, color: 'black', size: 1});
    if(i1>0)
        board1.create('segment', [springRings1[i1-1], springRings1[i1]], {color: 'black', strokeWidth: 1});
		};
	var vbase1=board1.create('polygon',[[0,-6.75],[18,-6.75],[18,-2.75],[0, -2.75]], {fillColor:'grey', withLines:false, vertices:{visible:false}});
//
	var hbase1=board1.create('polygon',[[-5,-6.75],[0,-6.75],[0,18],[-5, 18]], {fillColor:'grey', withLines:false, vertices:{visible:false}});
//
	board1.create('segment', [springHangup1, springRings1[0]], {color: 'black', strokeWidth: 1});
//
	board1.create('segment', [springRings1[numberOfSpringRings1-1], point1], {color: 'black', strokeWidth: 1});

	sover.on('up', function(){hand.moveTo([6.7,-0.1-2], 500-k1.Value())});
//
	function hook() {
		if(!isInDragMode) {
			if(board1.mode === board1.BOARD_MODE_DRAG) {
				isInDragMode = true;
			}
		}
    if(isInDragMode) {
        if(board1.mode !== board1.BOARD_MODE_DRAG) {
            isInDragMode = false;
        }
    }
}
	board1.on('move', function(){hook()});
      }
}
export default Boxes;
